import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import AnimationDataLoader from '../../components/Loaders/animationData';
import NumberedPagination from '../../components/numbered-pagination/NumberedPagination.component';
import TextEditor from '../../components/text-editor/TextEditor.component';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';
import { handleCodeChange } from '../../utils/helperFn';
import './TranslationMode.styles.css';

const pageSize = 20;

const TranslationMode = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const user = useSelector(state => state.user);

    let sharedSpacesConfig = React.useMemo(() => ({ top: 'main-toolbar' }), [])

    const [questionData, setQuestionData] = React.useState({
        currentQuest: null,
        offset: 0,
        pageNum: 0,
        currentQuestPage: 0,
        previous: false,
        next: true,
        totalQuestions: 1,
        isLoading: true,
        dataMap: null,
    });
    const [currentQuestionLanguages, setCurrentQuestionLanguages] = React.useState({
        data: [],
        langCodes: []
    });
    const [availableLang, setAvailableLang] = React.useState({
        isLoading: false,
        data: null,
    });
    const [formVal, setFormVal] = React.useState({
        questionText: { value: '', msg: null, editorUpdateSource: null },
        answerDesc: { value: '', msg: null },
        choices: [],
        selectedLanguage: '',
        translationMode: ''
    });

    const translationFormInitialState = React.useMemo(() => ({
        active: true,
        isLoading: false,
        langLockedMode: false,
        selectedLanguage: null,
        translationMode: location.state?.translationMode ? location.state?.translationMode : 'SELF_TRANSLATE'
    }), [location])

    const [translationForm, setTranslationForm] = React.useState(translationFormInitialState);

    const [permissionData, setPermissionData] = React.useState({
        isLoading: true,
        data: null
    })

    React.useEffect(() => {
        if (params?.bankId) {
            if (user.role === 'SME') {
                getAllBankAssignment();
            }
            getAllBankQuestions();
        }
    }, [params])

    React.useEffect(() => {
        if (questionData?.currentQuest?.reviewQuestionId && !availableLang.data) fetchAllLanguages();
    }, [questionData]);

    const getAllTranslatedQuestions = async (questionId, languages = availableLang.data) => {
        try {
            const res = await instance.get(`${getApiUrl("getAllTranslatedQuestions")}?questionId=${questionId}`);

            let dataToSet = [], langCodes = [];

            if (!!res.data && res.data.length > 0) {
                dataToSet = res.data.map(el => {
                    if (el.questionLanguage) {
                        const langObj = languages.find(innerEl => el.questionLanguage === innerEl.value);
                        if (!!langObj)
                            el.questionLanguage = langObj;
                        langCodes.push(el.questionLanguage.value);
                    }

                    return el;
                })
            }
            langCodes.push(questionData.currentQuest.questionLanguage)

            setCurrentQuestionLanguages({
                data: dataToSet,
                langCodes
            });
            return dataToSet;
        } catch (error) {
            console.log("getAllBankQuestions Err", error);
        }
    }

    const getAllBankQuestions = async (pageNum = 0, questPageNum = 0) => {
        try {
            setQuestionData({
                ...questionData,
                isLoading: true,
                currentQuest: null,
                pageNum
            })
            const dataTosend = {
                searchText: null,
                diffcultyLevel: ['easy', 'medium', 'difficult'],
                flagStatus: 'all'
            }
            const res = await instance.post(`${getApiUrl("getAllBankQuestions")}?bankId=${params.bankId}&offset=${pageNum}&pageSize=${pageSize}`, dataTosend);

            const currentQuest = pageNum === 0 ? res.data.reviewQuestionDtos[0] : res.data.reviewQuestionDtos[questPageNum % pageSize];

            setQuestionData({
                ...questionData,
                currentQuest,
                dataMap: { ...questionData.dataMap, [pageNum]: res.data.reviewQuestionDtos },
                totalQuestions: res.data.totalQuestions,
                currentQuestPage: pageNum === 0 ? pageNum : questPageNum,
                isLoading: false
            });

            if (pageNum > 0) {
                getAllTranslatedQuestions(currentQuest.reviewQuestionId);
            }

        } catch (error) {
            console.log("getAllBankQuestions Err", error);
        }
    }

    const fetchAllLanguages = async () => {
        try {
            const res = await instance.get(getApiUrl("getLanguagesSupport"));

            setAvailableLang({
                isLoading: false,
                data: res.data,
            });

            getAllTranslatedQuestions(questionData.currentQuest.reviewQuestionId, res.data)

        } catch (error) {
            console.log("Error", error)
        }
    }

    const getAllBankAssignment = async () => {
        try {
            const res = await instance.get(`${getApiUrl("getAllBankAssignment")}?bankId=${params.bankId}`);

            if (res.data.length == 1) {
                setPermissionData({
                    ...permissionData,
                    isLoading: false,
                    data: res.data[0].reviewBankPermissionDTO
                })
            }
        } catch (error) {
            setPermissionData({
                ...permissionData,
                isLoading: false,
            })

            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: errObj.statusText,
                type: 'error'
            }))
        }

    }

    const getPermission = (key) => {
        if (user.role === 'ADMIN')
            return true;
        return permissionData.data && permissionData.data[key]
    }

    const handleTextChange = (key, data, index) => {
        let dataToSet = null;

        if (key === 'choices') {
            dataToSet = formVal.choices.map((el) => {
                if (el.ind === index) {
                    el.value = data;
                    if (el.editorUpdateSource === "CUSTOM") el.editorUpdateSource = null;
                }

                return el;
            })
        } else if (['questionText', 'answerDesc'].includes(key)) { dataToSet = { value: data, msg: null, editorUpdateSource: null } }
        else { dataToSet = data }

        setFormVal({
            ...formVal,
            [key]: dataToSet
        })
    }

    const toggleTranslationForm = (forceClose) => {
        if (forceClose || !translationForm.langLockedMode) {
            setTranslationForm({
                ...translationForm,
                active: !translationForm.active,
                ...(forceClose ? { langLockedMode: false, selectedLanguage: null } : {})
            });
        }
        if (forceClose) {
            setFormVal({
                questionText: { value: '', msg: null },
                answerDesc: { value: '', msg: null },
                choices: [],
                selectedLanguage: '',
                translationMode: ''
            });
        }
    }

    const handleTranslationFormChange = (key, value) => {
        let dataToSet = null;
        if (key === "selectedLanguage") {
            dataToSet = availableLang.data.find(el => el.value === value.value);
        }
        else {
            dataToSet = value
        }


        setTranslationForm({
            ...translationForm,
            [key]: dataToSet,
            ...(key === "langLockedMode" ? { selectedLanguage: formVal.selectedLanguage } : {})
        })
    }

    const loadSelectOptions = React.useCallback(() => availableLang.data && availableLang.data.map(el => <MenuItem value={el} disabled={currentQuestionLanguages.langCodes.includes(el.value)} key={el.value}>{el.language}</MenuItem>), [availableLang, currentQuestionLanguages]);

    const handleAddEditTranslation = (langData, currentQuest) => {
        toggleTranslationForm(false);

        if (langData) {
            setFormVal({
                ...formVal,
                questionText: { value: langData.questionText, msg: null },
                answerDesc: { value: langData.answerDesc ? langData.answerDesc : '', msg: null },
                selectedLanguage: langData.questionLanguage,
                translationMode: translationForm.translationMode,
                choices: langData.questionJson?.choices.map((el, ind) => ({ value: el.choiceText, msg: null, ind: ind + 1, selected: langData.displayType === "MCA" ? langData.questionJson.correctChoiceIndices.includes(ind + 1) : langData.questionJson.correctChoiceIndex === (ind + 1), weightage: el.weightage }))
            })
        } else {
            setFormVal({
                ...formVal,
                questionText: { value: '', msg: null },
                selectedLanguage: translationForm.selectedLanguage,
                translationMode: translationForm.translationMode,
                choices: currentQuest?.questionJson?.choices.map((el, ind) => ({ value: '', msg: null, ind: ind + 1, selected: currentQuest.displayType === "MCA" ? currentQuest.questionJson.correctChoiceIndices.includes(ind + 1) : currentQuest.questionJson.correctChoiceIndex === (ind + 1), weightage: el.weightage }))
            })
        }
    }

    const handleCopyData = () => {
        setFormVal({
            ...formVal,
            questionText: { value: questionData.currentQuest.questionText, msg: null, editorUpdateSource: "CUSTOM" },
            answerDesc: { value: '', msg: null },
            choices: questionData?.currentQuest?.questionJson?.choices.map((el, ind) => ({ value: el.choiceText, msg: null, editorUpdateSource: "CUSTOM", ind: ind + 1, selected: questionData.currentQuest.displayType === "MCA" ? questionData.currentQuest.questionJson.correctChoiceIndices.includes(ind + 1) : questionData.currentQuest.questionJson.correctChoiceIndex === (ind + 1), weightage: el.weightage })),
        })
    }

    const handleOptionsChange = (data, key) => {
        let optionsData = [];
        data.map((element, index) => {
            let optiondata = element.value;
            let updatedOptiondata = handleCodeChange(optiondata, `answer-editor-${index}`);
            optionsData.push({
                "choiceText": updatedOptiondata,
                "weightage": element.weightage
            })
        })
        return optionsData;
    }


    const saveReviewTranslatedQuestion = async () => {
        try {
            const dataToSend = {
                questionLanguage: formVal.selectedLanguage.value,
                questionText: handleCodeChange(formVal.questionText.value, "TranslatequestionData"),
                // ansDescription: formVal.answerDesc.value,
                displayType: questionData.currentQuest.displayType,
                questionJson: {
                    choices: handleOptionsChange(formVal.choices, "choiceText"),
                    partialMarking: questionData.currentQuest.questionJson.partialMarking,
                    correctChoiceIndex: questionData.currentQuest.questionJson.correctChoiceIndex,
                    correctChoiceIndices: questionData.currentQuest.questionJson.correctChoiceIndices,
                    maximumAnswerSelection: questionData.currentQuest.questionJson.maximumAnswerSelection,
                }
            }

            const res = await instance.post(`${getApiUrl("saveReviewTranslatedQuestion")}?questionId=${questionData.currentQuest.reviewQuestionId}`, dataToSend);

            if (!translationForm.langLockedMode) {
                setTranslationForm({
                    ...translationForm,
                    selectedLanguage: null,
                    active: true
                });
                getAllTranslatedQuestions(questionData.currentQuest.reviewQuestionId);
            }

            dispatch(openSnackBar({
                type: "success",
                msg: res.data.message
            }))

        } catch (error) {
            console.log("Error", error);
            const msgObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: msgObj.statusText,
                type: 'error'
            }))
        }
    }

    const handleSave = () => {
        saveReviewTranslatedQuestion();
    }

    const handlePagination = async (page) => {
        let currentQuestPage = questionData.currentQuestPage;
        let currentQuest = questionData.currentQuest;
        if (!isNaN(page)) {
            currentQuestPage = page - 1;
        }
        if (page === "next") {
            currentQuestPage = questionData.currentQuestPage + 1;
        }
        if (page === "prev") {
            currentQuestPage = questionData.currentQuestPage - 1;
        }

        const pageNum = Math.floor((currentQuestPage) / pageSize);
        if (!questionData.dataMap[currentQuestPage] && currentQuestPage >= pageSize) {
            if (!questionData.dataMap[pageNum]) {
                getAllBankQuestions(pageNum, currentQuestPage);
                return;
            }
        }

        setTranslationForm({
            ...translationForm,
            isLoading: true
        })

        currentQuest = questionData.dataMap[pageNum][currentQuestPage % pageSize];
        let allTranslation = await getAllTranslatedQuestions(currentQuest.reviewQuestionId, availableLang.data);

        setQuestionData({
            ...questionData,
            currentQuestPage,
            currentQuest
        })

        if (translationForm.langLockedMode) {
            let langData = allTranslation.find(el => {
                if (el.questionLanguage.value === formVal.selectedLanguage.value) {
                    return el;
                }
            });

            handleAddEditTranslation(langData, currentQuest);
            setTimeout(() => setTranslationForm({
                ...translationForm,
                isLoading: false
            }), 500);
        } else {
            setTranslationForm({
                ...translationFormInitialState,
                selectedLanguage: translationForm.selectedLanguage,
            });
        }
    }

    // console.log("Question", questionData.currentQuest.answerDescription);

    return (
        <>
            {!questionData?.isLoading ? <>
                <div className='d-flex align-items-center justify-content-between border-bottom border-blue-gray-100 px-4 py-2 bg-white' style={{ minHeight: '50px' }}>
                    <div className='cm-left-col' style={{ width: '40%' }}>
                        <p className='mb-0 fw-semibold'>Question {questionData.currentQuestPage + 1}</p>
                    </div>
                    <div className={`cm-right-col d-flex align-items-center ${formVal.selectedLanguage ? 'justify-content-between' : 'justify-content-end'} ps-4`} style={{ width: '60%' }}>
                        {!translationForm.active &&
                            <>
                                {
                                    formVal.selectedLanguage && <div className='d-flex align-items-center'>
                                        <p className='cm-xs-txt mb-0 fw-medium text-blue-gray-500'>Translation Language: <span className='text-blue-800 fw-semibold cm-sm-txt'>{formVal.selectedLanguage.language}</span></p>
                                        <span className='cm-xs-txt mb-0 fw-medium text-blue-gray-500 ps-3'>Language Lock : </span>
                                        <label className='cm-toggle-switch ms-2'>
                                            <input type="checkbox" checked={translationForm.langLockedMode} onChange={() => handleTranslationFormChange('langLockedMode', !translationForm.langLockedMode)} />
                                            <span className="cm-slider" />
                                        </label>
                                    </div>
                                }

                                <div className='d-flex'>
                                    <div className='d-flex align-items-center me-3'>
                                        <div className='bg-gray-50 p-1 user-select-none rounded'>
                                            <span onClick={() => handleTextChange('translationMode', 'SELF_TRANSLATE')} className={`rounded px-3 py-1 cm-xs-txt d-inline-block fw-medium ${formVal.translationMode === "SELF_TRANSLATE" ? "cm-pointer-none bg-white text-blue-800" : "cm-pointer"}`} >Self Translate</span>
                                            <span onClick={() => handleTextChange('translationMode', 'AI_TRANSLATE')} className={`rounded px-3 py-1 cm-xs-txt d-inline-block fw-medium cm-pointer-none text-blue-gray-500 ${formVal.translationMode === "AI_TRANSLATE" ? "cm-pointer-none bg-white text-blue-800" : "cm-pointer"}`}>Translate by AI</span>
                                        </div>
                                    </div>
                                    <span onClick={() => { toggleTranslationForm(true) }} className='d-flex align-items-center border border-blue-gray-300 text-blue-800 px-3 py-1 rounded fw-medium cm-pointer cm-sm-txt bg-blue-gray-50'><FontAwesomeIcon icon={regular("plus")} className="pe-2" /> Add New Translation</span>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className='d-flex cm-trans-mode-rows'>
                    <div className='cm-left-col px-4 py-3 position-fixed overflow-auto'>
                        <div className='mb-3' dangerouslySetInnerHTML={{ __html: questionData.currentQuest.questionText }} />
                        {
                            questionData.currentQuest.questionJson.choices.map((el, ind) => {
                                return (
                                    <div key={'choices-' + ind} className={`d-flex ${ind > 0 ? 'mt-3' : ''}`}>
                                        <input type={questionData.currentQuest.displayType === "MCA" ? "checkbox" : "radio"} checked={getPermission("canViewSolution") ? (questionData.currentQuest.displayType === "MCA" ? questionData.currentQuest.questionJson.correctChoiceIndices.includes(ind + 1) : questionData.currentQuest.questionJson.correctChoiceIndex === (ind + 1)) : false} disabled />
                                        <div className='ps-3' dangerouslySetInnerHTML={{ __html: el.choiceText }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={`cm-right-col border-start border-blue-gray-100 bg-blue-gray-50 position-fixed overflow-auto ${translationForm.active ? 'd-flex align-items-center justify-content-center px-4 bg-blue-gray-50' : 'd-flex flex-column justify-content-between'}`}>
                        {translationForm.isLoading ?
                            <div className='d-flex align-items-center justify-content-center'><AnimationDataLoader /></div> :
                            <>
                                {!translationForm.active && <div id="main-toolbar" className='border border-top-0 border-start-0 border-blue-gray-100'></div>}
                                {translationForm.active ? <div className='w-100 py-3 px-4 rounded border border-blue-gray-100 bg-white'>
                                    <h3 className="h4 my-0 text-blue-800 cm-mmc-font mb-2">Add New Translation</h3>
                                    <p className="mb-0 text-blue-gray-500 fw-medium mb-4">Choose from below option to proceed further.</p>

                                    <div className='d-flex'>
                                        <div className='w-50 pe-3'>
                                            <label className='pb-2 text-blue-gray-700 cm-sm-txt pb-2 fw-medium'>Select a Language to add translation</label>
                                            <div className="position-relative">
                                                <Select
                                                    id="language-select"
                                                    fullWidth={true}
                                                    displayEmpty={true}
                                                    value={translationForm.selectedLanguage ? translationForm.selectedLanguage : ''}
                                                    onChange={e => handleTranslationFormChange('selectedLanguage', e.target.value)}
                                                    renderValue={(selected) => {
                                                        if (!selected) return <span className='text-blue-gray-400'>Select Language</span>

                                                        return selected.language;
                                                    }}
                                                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                                    MenuProps={{
                                                        className: "cm-select-modal"
                                                    }}
                                                >
                                                    {loadSelectOptions()}
                                                </Select>
                                                <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow cm-sm-txt text-blue-800 position-absolute" />
                                            </div>
                                        </div>
                                        <div className='w-50 ps-3'>
                                            <label className='pb-2 text-blue-gray-700 cm-sm-txt pb-2 fw-medium'>Translation Mode</label>
                                            <div className='d-flex align-items-center'>
                                                <div className='bg-gray-50 p-1 user-select-none rounded'>
                                                    <span onClick={() => handleTranslationFormChange('translationMode', 'SELF_TRANSLATE')} className={`rounded px-3 py-2 cm-sm-txt d-inline-block fw-medium ${translationForm.translationMode === "SELF_TRANSLATE" ? "cm-pointer-none bg-white text-blue-800" : "cm-pointer"}`} >Self Translate</span>
                                                    <span onClick={() => handleTranslationFormChange('translationMode', 'AI_TRANSLATE')} className={`rounded px-3 py-2 cm-sm-txt d-inline-block fw-medium cm-pointer-none text-blue-gray-500 ${translationForm.translationMode === "AI_TRANSLATE" ? "cm-pointer-none bg-white text-blue-800" : "cm-pointer"}`} >Translate by AI</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span onClick={() => handleAddEditTranslation(null, questionData.currentQuest)} className={`text-white rounded px-3 py-2 cm-sm-txt fw-medium d-inline-block mt-3 ${translationForm.selectedLanguage ? 'cm-pointer bg-action-blue' : 'cm-pointer-none cm-action-disabled-bg'}`}>Add New Translation</span>

                                    <div className='border-top border-blue-gray-100 my-4' />

                                    <div className='d-flex flex-wrap align-items-center'>
                                        <span className='d-inline-block mb-3 pe-3 fw-medium text-blue-gray-500 cm-sm-txt'>Available Translations:</span>
                                        {
                                            currentQuestionLanguages.data && currentQuestionLanguages.data.length > 0 ? currentQuestionLanguages.data.map(el => <span key={el.questionLanguage.value} onClick={() => handleAddEditTranslation(el, null)} className='rounded py-2 px-3 cm-sm-txt fw-medium border border-action-blue fw-medium me-3 mb-3 text-action-blue cm-pointer'>{el.questionLanguage.language}</span>) : <span className='cm-blue-gray-700 cm-sm-txt mb-3 pe-3'>No translations available!</span>
                                        }
                                    </div>
                                </div> :
                                    <>
                                        <div className='cm-trans-mode-edit-row px-4 py-3 overflow-auto'>
                                            <div className='mb-3'>
                                                <label className="pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block">Question Text</label>
                                                <TextEditor
                                                    data={formVal.questionText.value}
                                                    editorUpdateSource={formVal.questionText.editorUpdateSource}
                                                    handeTxtEditorChange={(data) => {
                                                        handleTextChange('questionText', data)
                                                    }}
                                                    id="TranslatequestionData"
                                                    sharedSpacesConfig={sharedSpacesConfig}
                                                />
                                            </div>
                                            {
                                                formVal.choices.map((el, ind) => {
                                                    return (
                                                        <div key={'trans-choices-' + el.ind} className={`d-flex align-items-center cm-answer-choice-editor ${ind > 0 ? 'mt-3' : ''}`}>
                                                            <input type={questionData.currentQuest.displayType === "MCA" ? "checkbox" : "radio"} checked={getPermission("canViewSolution") && el.selected} disabled />
                                                            <div className='px-3' style={{ flexGrow: '1' }}>
                                                                <TextEditor
                                                                    data={el.value}
                                                                    editorUpdateSource={el.editorUpdateSource}
                                                                    handeTxtEditorChange={(data) => {
                                                                        handleTextChange('choices', data, el.ind)
                                                                    }}
                                                                    placeholder={`Answer Choice ${el.ind}`}
                                                                    id={`answer-editor-${ind}`}
                                                                    sharedSpacesConfig={sharedSpacesConfig}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between px-4 py-2 border-top border-blue-gray-300'>
                                            <div className='d-flex align-items-center'>
                                                <span onClick={handleSave} className='bg-action-blue border border-action-blue text-white px-4 cm-pointer rounded py-2 cm-sm-txt'>Save</span>
                                                <span onClick={handleCopyData} className='ms-3 text-blue-800 bg-white px-3 cm-pointer rounded py-2 border border-blue-gray-300  cm-sm-txt'>Copy original question data</span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </> : <div className='d-flex align-items-center justify-content-center'><AnimationDataLoader /></div>}

            <div className='cm-trans-mode-footer d-flex justify-content-between px-4 py-3 position-fixed border-top border-blue-gray-100 bg-white'>
                <NumberedPagination showNextPrevButtons={true} handlePagination={handlePagination} totalPages={questionData.totalQuestions} currentPage={questionData.currentQuestPage + 1} />
            </div>
        </>

    )
}

export default TranslationMode;