
import React from 'react';
import { Helmet } from 'react-helmet';
const CodeSnippet = () => {
  const [firstRun, setFirstRun] = React.useState(false);
  function loadHtml() {
    return {
        __html: ` <link href="/codesnippet/lib/codemirror.css?v=233.1.1.20240517.060235" rel="stylesheet" type="text/css" />
        <style type="text/css">
            .CodeMirror {
                border: #ccc 1px solid;
                height: auto;
                min-height: 60px;
                font-size: 14px;
            }
    
            .CodeMirror-scroll {
                overflow-y: hidden;
                overflow-x: auto;
                height: inherit;
                min-height: 60px;
            }
    
            .CodeMirror-gutter {
                min-width: 35px !important;
            }
        </style><textarea row="20" cols="20" id="snippet"></textarea>`
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      if(window.CodeMirror && !firstRun) {
        setFirstRun(true)
      }
    }, 500);
  }, [])

  return <div>
    <Helmet>
    <script defer={true} type="text/javascript" src="/codesnippet/lib/codemirror.js?v=233.1.1.20240517.060235"></script>
    </Helmet> 
    {firstRun && <Helmet>
    <script defer={true} type="text/javascript">{`
    var codeLanguages = {
      BASH: { Name: "shell", Mode: "text/x-sh", lib: ["shell/shell.js"] },
      C: { Name: "c", Mode: "text/x-csrc", lib: ["clike/clike.js"] },
      CLANG: { Name: "c", Mode: "text/x-csrc", lib: ["clike/clike.js"] },
      CLOJURE: { Name: "clojure", Mode: "text/x-clojure", lib: ["clojure/clojure.js"] },
      COBOL: { Name: "cobol", Mode: "text/x-cobol", lib: ["cobol/cobol.js"] },
      CPP: { Name: "cpp", Mode: "text/x-c++src", lib: ["clike/clike.js"] },
      CS: { Name: "csharp", Mode: "text/x-csharp", lib: ["clike/clike.js"] },
      CSHARP: { Name: "csharp", Mode: "text/x-csharp", lib: ["clike/clike.js"] },
      CSS: { Name: "css", Mode: "text/css", lib: ["css/css.js"] },
      DART: { Name: "dart", Mode: "application/dart", lib: ["dart/dart.js", "clike/clike.js"] },
      ELIXIR: { Name: "elixir", Mode: "elixir", lib: ["elixir/elixir.js"] },
      ERLANG: { Name: "erlang", Mode: "text/x-erlang", lib: ["erlang/erlang.js"] },
      FORTRAN: { Name: "fortran", Mode: "text/x-fortran", lib: ["fortran/fortran.js"] },
      GO1: { Name: "go1", Mode: "text/x-go", lib: ["go/go.js"] },
      HASKELL: { Name: "haskel", Mode: "text/x-haskell", lib: ["haskell/haskell.js"] },
      HTML: { Name: "html", Mode: "text/html", lib: ["htmlmixed/htmlmixed.js", "xml/xml.js", "javascript/javascript.js", "css/css.js"] },
      JAVA: { Name: "java", Mode: "text/x-java", lib: ["clike/clike.js"] },
      JAVASCRIPT: { Name: "javascript", Mode: "text/javascript", lib: ["javascript/javascript.js"] },
      JAVASCRIPT8: { Name: "javascript8", Mode: "text/javascript", lib: ["javascript/javascript.js"] },
      JSON: { Name: "json", Mode: "application/json", lib: ["javascript/javascript.js"] },
      JULIA: { Name: "julia", Mode: "text/x-julia", lib: ["julia/julia.js"] },
      KOTLIN: { Name: "kotlin", Mode: "text/x-kotlin", lib: ["clike/clike.js"] },
      NODE: { Name: "node", Mode: "text/javascript", lib: ["javascript/javascript.js"] },
      OBJECTIVEC: { Name: "objectiveC", Mode: "text/x-objectivec", lib: ["clike/clike.js"] },
      OCTAVE: { Name: "octave", Mode: "text/x-octave", lib: ["octave/octave.js"] },
      PERL: { Name: "perl", Mode: "text/x-perl", lib: ["perl/perl.js"] },
      PHP: { Name: "php", Mode: "application/x-httpd-php", lib: ["xml/xml.js", "php/php.js", "javascript/javascript.js", "clike/clike.js", "htmlmixed/htmlmixed.js"] },
      PLSQL: { Name: "plsql", Mode: "text/x-plsql", lib: ["sql/sql.js"] },
      PYTHON: { Name: "python", Mode: "text/x-python", lib: ["python/python.js"] },
      R: { Name: "r", Mode: "text/x-rsrc", lib: ["r/r.js"] },
      RUBY: { Name: "ruby", Mode: "text/x-ruby", lib: ["ruby/ruby.js"] },
      SAS: { Name: "sas", Mode: "text/x-sas", lib: ["sas/sas.js"] },
      SCALA2: { Name: "scala2", Mode: "text/x-scala", lib: ["clike/clike.js"] },
      SHELL: { Name: "shell", Mode: "text/x-sh", lib: ["shell/shell.js"] },
      SQL: { Name: "mssql", Mode: "text/x-mysql", lib: ["sql/sql.js"] },
      SWIFT: { Name: "swift", Mode: "text/x-swift", lib: ["swift/swift.js"] },
      SWIFT2: { Name: "swift2", Mode: "text/x-swift", lib: ["swift/swift.js"] },
      SWIFT4: { Name: "swift4", Mode: "text/x-swift", lib: ["swift/swift.js"] },
      VB: { Name: "vb", Mode: "text/x-vb", lib: ["vb/vb.js"] },
      VBA: { Name: "vba", Mode: "text/vbscript", lib: ["vbscript/vbscript.js"] },
      VBSCRIPT: { Name: "vba", Mode: "text/vbscript", lib: ["vbscript/vbscript.js"] },
      XML: { Name: "xml", Mode: "application/xml", lib: ["xml/xml.js"] },
      XQUERY: { Name: "xquery", Mode: "application/xquery", lib: ["xquery/xquery.js"] },
  };

  var url_string = window.location.href;
  var url = new URL(url_string);
  var mode = url.searchParams.get("mode").toUpperCase();
  var code = url.searchParams.get("code");
  document.getElementById("snippet").innerHTML = code;

  var cEditor = CodeMirror.fromTextArea(document.getElementById("snippet"), {
      lineNumbers: true,
      matchBrackets: true,
      viewportMargin: Infinity,
      mode: codeLanguages[mode].Mode,
      readOnly: false
  });

  refreshIframeHeight();

  cEditor.on('keyup', function () {
      refreshIframeHeight();
  });

  function refreshIframeHeight() {
      try {
          let snippetDocument = this.document;
          let height = snippetDocument.body.querySelector('.CodeMirror').getBoundingClientRect().height
          parent.postMessage({
              height
          }, window.location.origin)

      } catch (e) {
          console.log(e)
      }
  }

  function getScript(source, callback) {
      var script = document.createElement('script');
      var prior = document.getElementsByTagName('script')[0];
      script.async = 1;

      script.onload = script.onreadystatechange = function (_, isAbort) {
          if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
              script.onload = script.onreadystatechange = null;
              script = undefined;

              if (!isAbort && callback) setTimeout(callback, 0);
          }
      };

      script.src = source;
      prior.parentNode.insertBefore(script, prior);
  }
  for (let i = 0; i < codeLanguages[mode].lib.length; i++) {
      getScript('/codesnippet/mode/' + codeLanguages[mode].lib[i], function () {
          cEditor.setOption('mode', codeLanguages[mode].Mode);
      });
  }
  `}</script>
  </Helmet>}
    
    <div dangerouslySetInnerHTML={loadHtml()} />
  </div>
}

export default CodeSnippet;
