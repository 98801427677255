import * as React from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import { getApiUrlSkill } from "../../utils/apiUrls";
import { Menu } from "@mui/material";
import instance from '../../utils/axios';
import { sanitiseInput } from "../../utils/helperFn";

const UserInput = (props) => {
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = React.useState({
    status: false,
    data: [],
    shouldShow: true,
    inputVal: "",
  });
  const [activeData, setActiveData] = React.useState("Skill_type");
  const searchButtonHandle = (event) => {
    if (searchResult.inputVal === "") {
      dispatch(
        openSnackBar({
          msg: "Input Data",
          type: "error",
        })
      );
    } else {
      props.fetchData(searchResult.inputVal);
    }
    event.preventDefault();
  };
  const handleChange = (e) => {
    setActiveData(sanitiseInput(e.target.value));
  };

  const handleInpChange = (txt, hideDropdown) => {
    setSearchResult({
      ...searchResult,
      inputVal: sanitiseInput(txt),
      shouldShow: !hideDropdown,
      status: false,
    });
  };

  const fetchResult = async () => {
    try {
      const res = await instance.get(
        `${getApiUrlSkill("suggestionData")}?taxonomyName=${
          searchResult.inputVal
        }&entityType=${activeData}`
      );
      if (!!res.data) {
        setSearchResult({
          ...searchResult,
          status: res.data.length > 0,
          data: res.data,
          shouldShow: true,
        });
      }
    } catch (error) {
      dispatch(
        openSnackBar({
          msg: "Error in generating data",
          type: "error",
        })
      );
    }
  };

  const handleSearchClick = (txt) => {
    //setInputVal(txt);
    //setSearchResult({...searchResult, status: false, shouldShow: true})
    handleInpChange(txt, true);
  };

  useDebounce(
    () => {
      if (searchResult.inputVal !== "" && searchResult.shouldShow) {
        fetchResult();
      }
    },
    [searchResult.inputVal],
    800
  );

  const handleClose = () => {
    setSearchResult({
      ...searchResult,
      status: false,
    });
  };

  return (
    <div className="container-fluid user-input m-0 d-flex justify-content-between">
      <form onSubmit={searchButtonHandle}>
        <div className="d-flex py-2 px-4 mx-1">
          <span className="text-blue-800 cm-mmc-font cm-sm-txt fw-medium  m-0 my-auto me-2">
            Search:
          </span>
          <Select
            value={activeData}
            onChange={handleChange}
            fullWidth={true}
            sx={{
              width: "220px",
              height: "40px",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            <MenuItem value="Skill">Skill</MenuItem>
            <MenuItem value="Job">Job</MenuItem>
            <MenuItem value="Skill_type">Skill type</MenuItem>
            <MenuItem value="Industry">Industry</MenuItem>
            <MenuItem value="Framework/Tool/Library">
              Framework/Tool/Library
            </MenuItem>
            {/* <MenuItem value="Trait">Trait</MenuItem> */}
            {/* <MenuItem value="Relation">Relation</MenuItem> */}
          </Select>
          <div className="my-auto ms-2">
            <input
              type="text"
              value={searchResult.inputVal}
              onChange={(e) => handleInpChange(e.target.value)}
              name="search"
              placeholder={
                props.inputVal === "" ? "Enter skill or JD" : props.inputVal
              }
              className="user-input_search-bar cm-btn-radius pe-2"
              id="skillDashSearch"
            />
            <FontAwesomeIcon
              icon={regular("search")}
              className="user-input_icon"
            />
            <Menu
              id="auto-suggestion-menu"
              anchorEl={document.getElementById("skillDashSearch")}
              open={searchResult.status}
              onClose={handleClose}
              className="cm-action-menu"
              MenuListProps={{
                disablePadding: true,
                className: "cm-select-menu",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              elevation={0}
              PaperProps={{
                style: {
                  width: 300,
                  height:300
                },
              }}
            >
              {searchResult.data.map((el) => (
                <MenuItem key={el} onClick={() => handleSearchClick(el)}>
                  {el}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <button
            type="submit"
            className="btn btn-success bg-action-blue cm-xs-txt ms-n2"
            onClick={searchButtonHandle}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};
export default UserInput;
