import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import { checkPassword } from '../../utils/helperFn';
import CustomToolTip from '../custom-tooltip/CustomTooltip.component';
import CustomButtonLoader from '../global/CustomButtonLoader.component';

const initial_state = {
    email: {
        value: '',
        isRequired: true,
        msg: null
    },
    password: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordVisible: false,
    passwordConfirm: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordConfirmVisible: false,
    validOptions: {
        requiredFields: ['email', 'password', 'passwordConfirm'],
        errorFields: [],
    },
};

const SetPasswordForm = ({ t, decryptId }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [formVal, setFormVal] = React.useState(initial_state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value,
            }
        })
    }

    const handlePassVisiblity = (fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: !formVal[fieldName]
        })
    }

    React.useEffect(() => {
        !!decryptId && getEmailId();

        // eslint-disable-next-line
    }, [decryptId])
    
    const getEmailId = async () => {
        try {
            setIsLoading(true);
            const res = await axios.get(`${getApiUrl('decryptId')}?id=${encodeURIComponent(decryptId)}`);
    
            if(!!res.data) {
                setIsLoading(false);
                setFormVal({
                    ...formVal,
                    email: {
                        ...formVal['email'],
                        value: res.data,
                    }
                })
            }
        } catch (err) {
            setIsLoading(false);
            const errObj = apiErrorHandler(err);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const setPassword = async (data) => {
        try {
            setIsLoading(true);
            const res = await axios.post(`${getApiUrl('resetPassword')}`, data);
    
            if(res.data.status) {
                setIsLoading(false);
                dispatch(openSnackBar({
                    msg: t(res.data.message),
                    type: 'success'
                }));

                navigate(`/login`);
            } else {
                throw Error(res.data);
            }
        } catch (err) {
            setIsLoading(false);
            const errObj = apiErrorHandler(err);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const getReqMsg = (fieldName) => {
        if(fieldName === 'email') {
            return t('email_required');
        } else if(fieldName === 'password' || fieldName === 'passwordConfirm') {
            return t('new_password_required');
        } else {
            return t('field_required')
        }
    }

    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = JSON.parse(JSON.stringify(formVal));

        formVal.validOptions.requiredFields.forEach(el => {
            if(currentFormVal[el].value === '') {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                //Email Validation
                if(el === 'email') {
                    // eslint-disable-next-line
                    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                    currentFormVal.email.msg = !regex.test(currentFormVal.email.value) ? t("valid_email_address"): null;
                    return;         
                }

                //Password Validation
                if(el === 'password') {
                    const isValid = checkPassword(currentFormVal.password.value);
                    currentFormVal.password.msg = !isValid ? t("valid_password") : null;
                    return; 
                }
                if(el === 'passwordConfirm') {
                    const isValid = checkPassword(currentFormVal.passwordConfirm.value);
                    currentFormVal.passwordConfirm.msg = !isValid ? t("valid_password") : null;
                }

                //Match Passwords
                if(el === 'passwordConfirm') {
                    currentFormVal.passwordConfirm.msg = currentFormVal.password.value !== currentFormVal.passwordConfirm.value ? t('passwords_not_match') : null;
                    return;
                }

                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if(formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if(isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
            // alert('SetPassword');
            !isLoading && encProcess();
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }


    const encProcess = async () => {
        try {
            setIsLoading(true);
            const getKey = await axios.get(getApiUrl('getSk'));
            if(!!getKey.data) {
                setIsLoading(false);
                const JSEncrypt = window.JSEncrypt;

                //RSA Public Key
                const rsaKey = getKey.data.key;
                const passToEncrypt = formVal.password.value;
                const confirmPassToEncrypt = formVal.passwordConfirm.value;
                const skid = getKey.data.id;

                //encrypt AES key with RSA public key
                var rsaEncrypt = new JSEncrypt({default_key_size: 2048});
                rsaEncrypt.setPublicKey(rsaKey);
                var rsaEncryptedAesKey = rsaEncrypt.encrypt(passToEncrypt.toString());
                var rsaEncryptedAesKey2 = rsaEncrypt.encrypt(confirmPassToEncrypt.toString());

                var encryptedTransaction = { 
                    skid,
                    email: formVal.email.value,
                    password: rsaEncryptedAesKey.toString(), 
                    confirmPassword: rsaEncryptedAesKey2.toString(), 
                };

                setPassword(encryptedTransaction)
            }

        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleValidation();
    }

    return(
        <form onSubmit={handleFormSubmit} className="cm-auth-form cm-login-form">
            <div className="form-group mb-3">
                <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("login_email_label")}</label>
                <input type="email" className="form-control text-blue-800 fw-medium" id="email" value={formVal.email.value} placeholder={t("login_email_placeholder")} onChange={(e) => handleFieldChange(e, 'email')} readOnly={true} />
                {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("set_a_password")}</label>
                <div className="cm-icon-field position-relative cm-double-icon-field">
                    <input type={formVal.passwordVisible ? 'text': 'password'} className="form-control text-blue-800 fw-medium" id="password" value={formVal.password.value} placeholder={t("password")} onChange={(e) => handleFieldChange(e, 'password')} />
                    <FontAwesomeIcon icon={formVal.passwordVisible ? regular('eye-slash') : regular('eye')}  className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} onClick={() => handlePassVisiblity('passwordVisible')} />
                    <CustomToolTip 
                        title={
                            <>
                                <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                    <li>{t("pass_point_1")}</li>
                                    <li>{t("pass_point_2")}</li>
                                    <li>{t("pass_point_3")}</li>
                                    <li>{t("pass_point_4")}</li>
                                </ul>
                            </>
                        }
                    >
                        <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 position-absolute cm-pass-info-icon`} />
                    </CustomToolTip>
                </div>
                {formVal.password.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.password.msg}</span>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("confirm_password")}</label>
                <div className="cm-icon-field position-relative cm-double-icon-field">
                    <input type={formVal.passwordConfirmVisible ? 'text': 'password'} className="form-control text-blue-800 fw-medium" id="password-confirm" value={formVal.passwordConfirm.value} placeholder={t("password")} onChange={(e) => handleFieldChange(e, 'passwordConfirm')} />
                    <FontAwesomeIcon icon={formVal.passwordConfirmVisible ? regular('eye-slash') : regular('eye')}  className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} onClick={() => handlePassVisiblity('passwordConfirmVisible')} />
                    <CustomToolTip 
                        title={
                            <>
                                <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                    <li>{t("pass_point_1")}</li>
                                    <li>{t("pass_point_2")}</li>
                                    <li>{t("pass_point_3")}</li>
                                    <li>{t("pass_point_4")}</li>
                                </ul>
                            </>
                        }
                    >
                        <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 position-absolute cm-pass-info-icon`} />
                    </CustomToolTip>
                </div>
                {formVal.passwordConfirm.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.passwordConfirm.msg}</span>}
            </div>

            <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                <CustomButtonLoader
                    showLoadingState ={isLoading}
                    colorTheme= "blue"
                    icon={regular('angle-right')}
                    reverseIconDirection={false}
                    buttonLabel={t('set_up_new_password')}
                    buttonStyle={"px-12 py-10 cm-mar-left-icon"}
                    handleOnClick={handleFormSubmit}
                />
            </div>
        </form>
    )

}

export default SetPasswordForm;