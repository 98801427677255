export const errorHandler = (err) => {
    if (err.response) {
        return {
            code: err.response.status,
            statusText: err.response.statusText
        }
    } else {
        return {
            code: err.code,
            statusText: err.message
        }
    }
}

export const apiErrorHandler = (err) => {
    if (err.response) {
        return {
            code: err.response.status,
            statusText: !!err.response.data.message ? err.response.data.message : err.response.data.error
        }
    } else {
        return {
            code: err.code,
            statusText: err.message
        }
    }
}