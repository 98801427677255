import React from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLocation, useSearchParams } from "react-router-dom";
import { MenuItem, Select } from '@mui/material';
import { useDispatch } from "react-redux";
import instance from "../../utils/axios";
import { getApiUrl } from "../../utils/apiUrls";
import { apiErrorHandler } from "../../utils/errorHandler";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { t } from "i18next";
import AnimationDataLoader from "../../components/Loaders/animationData";
import { sanitiseInput } from "../../utils/helperFn";

const ViewQuestion = () => {

    const location = useLocation();
    const bank = location?.state?.bank;
    const question = location?.state?.question;

    const [searchParams] = useSearchParams();
    const questionId = searchParams.get("question_id");

    const dispatch = useDispatch();

    const [breadcrumbData, setBreadcrumbData] = React.useState([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "My Bank",
            link: '/my-bank'
        },
        {
            label: bank?.name,
            link: `/my-bank/${bank?.id}`
        },
        {
            label: 'View Question',
            link: ''
        },
    ]);

    const [isOriginalQuestion, setIsOriginalQuestion] = React.useState(true);
    const [isTranslationAvailable, setIsTranslationAvailable] = React.useState(false);
    const [fetchAllVersions, setFetchAllVersions] = React.useState(true);
    const [originalQuestions, setOriginalQuestions] = React.useState([]);
    const [translationQuestions, setTranslationQuestions] = React.useState({});
    const [selectedOriginalQuestionVersion, setSelectedOriginalQuestionVersion] = React.useState(0);
    const [selectedQuestion, setSelectedQuestion] = React.useState({});
    const [allTranslatedLanguages, setAllTranslatedLanguages] = React.useState([]);
    const [translationLanguageSelected, setTranslationLanguageSelected] = React.useState("");
    const [translationLanguageSelectedOptions, setTranslationLanguageSelectedOptions] = React.useState([]);
    const [translationVersionSelected, setTranslationVersionSelected] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showDefaultVersionOriginal, setShowDefaultVersionOriginal] = React.useState(true);
    const [showDefaultVersionTranslation, setShowDefaultVersionTanslation] = React.useState(false);


    React.useEffect(() => {
        if (fetchAllVersions) getAllVersionForQuestion(questionId);
    }, [fetchAllVersions]);

    const loadSelectVersionOptions = (list) => list.map((el, index) => (
        <MenuItem value={parseInt(el.versionNumber)} key={index}>
            <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{`Version : ${parseInt(el.versionNumber)}`}</span>
        </MenuItem>
    ));

    const loadSelectLanguageOptions = (list) => list.map((el, index) => (
        <MenuItem value={el} key={index}>
            <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{el}</span>
        </MenuItem>
    ));

    const getAllVersionForQuestion = async (questionId) => {

        try {
            const res = await instance.get(`${getApiUrl('getAllVersions')}/${questionId}`);
            if (res.data) {
                setOriginalQuestions(res.data.reviewQuestions);
                setTranslationQuestions(res.data.translatedQuestions);
                const languages = Object.keys(res.data.translatedQuestions).map((element) => {
                    return element;
                })
                setAllTranslatedLanguages(languages);
                setSelectedQuestion(res.data.reviewQuestions?.find((question) => parseInt(question.versionNumber) === 1));
                setFetchAllVersions(false);
                setIsLoading(false);
            }

        } catch (error) {
            const errorObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errorObj.statusText),
                type: "error"
            }))
        }
    }

    const handleViewQuestionInputChange = (type) => {
        if (type === "original_question") {
            setIsTranslationAvailable(false);
            setIsOriginalQuestion(true);
        }
        else if (type === "translations_available") {
            setIsOriginalQuestion(false);
            setIsTranslationAvailable(true);
        }
    }

    // const handleShowFlags = (event) => {

    // }

    const handleSelectChange = (type, event) => {
        if (type === 'original_question_select') {
            setShowDefaultVersionOriginal(true);
            setShowDefaultVersionTanslation(false);
            setTranslationLanguageSelected("");
            setTranslationLanguageSelectedOptions([]);
            setTranslationVersionSelected(0);
            setSelectedOriginalQuestionVersion(event.target.value);
            setSelectedQuestion(originalQuestions?.find((question) => parseInt(question.versionNumber) === parseInt(event.target.value)));
        }
        else if (type === 'translation_available_select-task-language') {
            setTranslationLanguageSelected(event.target.value);
            setShowDefaultVersionTanslation(false);
            setTranslationVersionSelected(0);
            setTranslationLanguageSelectedOptions(translationQuestions[event.target.value]);
        }
        else if (type === 'translation_available_select-task-version') {
            setShowDefaultVersionTanslation(true);
            setShowDefaultVersionOriginal(false);
            setSelectedOriginalQuestionVersion(0);
            setTranslationVersionSelected(event.target.value);
            setSelectedQuestion(translationQuestions[translationLanguageSelected]?.find((question) => parseInt(question.versionNumber) === parseInt(event.target.value)));
        }
    }



    return (
        <>
            <Breadcrumb data={breadcrumbData} />
            <div className="cm-create-event-container d-flex flex-column align-items-center justify-content-center ">
                <div className="border border-blue-gray-300 rounded mt-3 mb-3 bg-white">
                    <div className="px-3 py-2 cm-create-modal-form-header">
                        <span className="cm-sm-txt text-blue-gray-700 fw-semibold">Select Your View :</span>
                    </div>

                    <div className="mt-2 mb-2 px-4 pt-2 pb-2 d-flex align-items-center justify-content-between gap-4 user-select-none">
                        <div className="d-flex align-items-center justify-content-center">
                            <input type="radio" id="original_question" name="original_question" className='cm-pointer' onChange={() => handleViewQuestionInputChange("original_question")} checked={isOriginalQuestion} />
                            <label htmlFor="original_question" className='px-2 text-blue-800 cm-sm-txt fw-medium'>Original Question</label>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <input type="radio" id="translations_available" disabled={!(allTranslatedLanguages && allTranslatedLanguages?.length > 0)} name="translations_available" className='cm-pointer' onChange={() => handleViewQuestionInputChange("translations_available")} checked={isTranslationAvailable} />
                            <label htmlFor="translations_available" className='px-2 text-blue-800 cm-sm-txt fw-medium'>Translations Available</label>
                        </div>
                    </div>

                    {(isOriginalQuestion) &&
                        <div className="cm-create-task-form px-4 cm-create-modal-form-header">
                            <div className="mt-3 mb-3 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="original_question_select-task" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Select Version: </label>
                                    <Select
                                        id="original_question_select"
                                        defaultValue={1}
                                        value={selectedOriginalQuestionVersion}
                                        onChange={e => handleSelectChange('original_question_select', e)}
                                        renderValue={(selected) => {
                                            if (selected > 0) return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{showDefaultVersionOriginal ? `Version : ${selected}` : ''}</span>;
                                            else return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{showDefaultVersionOriginal ? `Version : ${1}` : ''}</span>;
                                        }}
                                    >
                                        {loadSelectVersionOptions(originalQuestions)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    }

                    {(isTranslationAvailable) &&
                        <div className="cm-create-task-form px-4 cm-create-modal-form-header">
                            <div className="mt-3 mb-3 col-sm-12 d-flex flex-column gap-3">
                                <div className="form-group">
                                    <label htmlFor="translation_available_select-task-language" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Select Language: </label>
                                    <Select
                                        id="translation_available_select-task-language"
                                        value={translationLanguageSelected}
                                        onChange={e => handleSelectChange('translation_available_select-task-language', e)}
                                    // renderValue={(selected) => {
                                    //     if (!selected) return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>''</span>;
                                    //     return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{selected}</span>;
                                    // }}
                                    >
                                        {loadSelectLanguageOptions(allTranslatedLanguages)}
                                    </Select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="translation_available_select-task-version" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Select Version: </label>
                                    <Select
                                        id="translation_available_select-task-version"
                                        value={translationVersionSelected}
                                        onChange={e => handleSelectChange('translation_available_select-task-version', e)}
                                        renderValue={(selected) => {
                                            return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{showDefaultVersionTranslation ? `Version : ${selected}` : ''}</span>;
                                        }}
                                    >
                                        {loadSelectVersionOptions(translationLanguageSelectedOptions)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    }

                    {/* <div className="mt-1 mb-1">
                        <div className="d-flex gap-2 justify-content-end px-4 py-1">
                            <input type="checkbox" name="show-flag" id="show-flag"
                                // checked={checkedQuestions.includes(question.reviewQuestionId)}
                                onChange={(e) => handleShowFlags(e)}
                                style={{ transform: "scale(1.1)", cursor: "pointer" }}
                            />
                            <span className='cm-sm-txt text-blue-gray-700 fw-semibold'>Show Flags</span>
                        </div>
                    </div> */}
                </div>

                <div className="bank-question-card border border-blue-gray-300 rounded pt-1 bg-white mt-3 mb-4 position-relative overflow-hidden" style={{ width: '70%', minHeight: '100px' }}>
                    {!isLoading && selectedQuestion ?
                        <>
                            <div className='cm-left-col d-flex flex-column justify-content-between px-3 cm-create-modal-form-header'>
                                <div className="bank-ques-id">
                                    <div className='ques-text'>
                                        {selectedQuestion?.questionText !== null && <div dangerouslySetInnerHTML={{ __html: sanitiseInput(selectedQuestion.questionText) }}></div>}
                                    </div>

                                    <div className='my-3'>
                                        {selectedQuestion?.questionJson?.choices.map((element, index) => {
                                            return (<div key={index} className='my-2 answer-text d-flex align-items-start'>
                                                {selectedQuestion.displayType === "MCQ" ?
                                                    <input className={`inline-block mt-1 mx-3 cm-pointer ${'disabledClass'}`}
                                                        type="radio"
                                                        value={index}
                                                        name={'answerOptions' + questionId}
                                                        checked={selectedQuestion.questionJson.correctChoiceIndex === index + 1}
                                                        disabled={true}
                                                    />
                                                    :
                                                    <input className='inline-block mt-1'
                                                        type="checkbox"
                                                        value={index}
                                                        name={'answerOptions' + questionId}
                                                        checked={selectedQuestion.questionJson.correctChoiceIndex === index + 1}
                                                        disabled={true}
                                                    />
                                                }
                                                <div className='w-100 mx-2' dangerouslySetInnerHTML={{ __html: sanitiseInput(element.choiceText) }} />
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                            {
                                selectedQuestion.difficultyLevel &&
                                <div className='mcqCardConatinerBottom w-100 pt-2 pb-1'>
                                    <div className='mcqCardConatinerBottomTags mcqCardConatinerBottomTagsPreviewCard d-flex align-items-center flex-wrap ps-3'>
                                        <span className='mcq-tags mb-1'>{selectedQuestion.difficultyLevel}</span>
                                        <span className='mcq-tags mb-1'>{selectedQuestion.displayType}</span>
                                        <span className='mcq-tags mb-1'>{question.topic}</span>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        (<div className="d-flex justify-content-center align-items-center">
                            <AnimationDataLoader />
                        </div>)
                    }
                </div>
            </div>
        </>
    );
}

export default ViewQuestion;

